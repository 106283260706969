<footer style="padding: 0px;">
    <!--<div class="text-live"> 
      LIVE BODY
      <div class="bottom-back"></div>
    </div>-->
    
      <div class="container" style="padding: 0px;">
        <br>
        <br>
        <div class="row align-items-end justify-content-md-center" style="padding: 0px;">
          <div class="col-sm-5 col-md-5 col-12" style="vertical-align: middle;margin: auto;padding: auto;text-align: center;">
            <h3><b>LIVE BODY</b></h3>
            <p>Una herramienta integral para las personas en la búsqueda de una vida plena y feliz</p>
            <p><b>¡Atrévete a explotar tu máximo potencial!</b></p>
          </div>
          <div class="col-sm-5 col-md-5 col-12" style="text-align: center;">
            <a href="https://www.instagram.com/livebodymx/"><img src="assets/img/icons/instagram2.png" width="18px" alt=""></a>&nbsp;&nbsp;&nbsp;&nbsp;
            <a href="https://www.facebook.com/LiveBodyMx/"> <img src="assets/img/icons/facebook2.png" width="18px" alt=""></a> &nbsp;&nbsp;
            <!--<a href=""> <img src="assets/img/icons/twitter2.png" width="18px" alt=""></a> &nbsp;&nbsp;-->
            <a href="https://www.linkedin.com/company/live-body"><img src="assets/img/icons/linkedin2.png" width="18px" alt=""></a>
              <br>
            <br>
            <h6>
              <a target="_blank" class="link" href="assets/pdf/TerminosyCondiciones.pdf">Términos y condiciones</a> <br>
              <a target="_blank" class="link" href="assets/pdf/Aviso_de_Privacidad.pdf">Aviso de privacidad</a>
            </h6>
            <br>
            <h6>
              Contacto: <br>
              <a target="_blank" class="link" rel="noopener noreferrer" href="mailto:contacto@livebody.com.mx ?subject=Quiero%20información">contacto@livebody.com.mx </a><br>
              <a target="_blank" class="link" rel="noopener noreferrer" href="tel:5630137055">56 3013 7055</a>
            </h6>
        </div>
    </div>
        <br>
        <br>
</div>
    </footer>
