<!--Header-->


<nav class="navbar navbar-expand-lg " [ngClass]="{'fixed': isSticky}">
    <div class="container-fluid mx-auto text-center " style="text-align: center!important;">
      <!--<a routerLink="/**" class="img-logo"><img src="assets/img/icons/logo.png"  alt="" class="img-logo" ></a>-->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"><img src="assets/img/icons/menu-2.png"  alt=""></span>
      </button>
      <!--<div class="collapse navbar-collapse" id="navbarSupportedContent" >
        <ul class="navbar-nav mx-auto text-center ">
          <li class="nav-item">
            <a routerLink="/**" class="img-logo page"><img src="assets/img/header/logo-live.png"  alt="" class="img-logo" ></a>
          </li>
          <li class="nav-item">
            <a class="nav-link border-pink page" routerLink="/corp"  routerLinkActive="active-link">EMPRESAS</a>
          </li>
          
          <li class="nav-item">
            <a class="nav-link border-pink page" routerLink="/salud"  routerLinkActive="active-link">SALUD INTEGRAL</a>
          </li>
          <li class="nav-item">
            <a class="nav-link border-pink page" routerLink="/nom035"  routerLinkActive="active-link">NOM-035</a>
          </li>
          <li class="nav-item">
            <a class="nav-link border-pink page" routerLink="/body" routerLinkActive="active-link">CUERPO</a>
          </li>
          <li class="nav-item">
            <a class="nav-link border-pink page" routerLink="/balance"  routerLinkActive="active-link" >EQUILIBRIO</a>
          </li>
          <li class="nav-item">
            <a class="nav-link border-pink page" routerLink="/increase"  routerLinkActive="active-link">CRECIMIENTO</a>
          </li>
          <li class="nav-item">
            <a class="nav-link border-pink" href="/#retos" routerLinkActive="active-link" >RETOS </a>
          </li>
          <li class="nav-item">
            <a class="nav-link border-pink page" routerLink="/about" routerLinkActive="active-link" >ABOUT US </a>
          </li>
          <li class="nav-item">
            <a class="nav-link btn-pink page" href="https://corp.livebody.com.mx/login"  routerLinkActive="active-link"  >INICIAR SESIÓN </a>
          </li>
          <li class="nav-item">
            <a class="nav-link border-pink page" routerLink="/blog"  routerLinkActive="active-link" > BLOG</a>
          </li>

        </ul>
      </div> -->
    </div>
  </nav>

