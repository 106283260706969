import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import { HomeComponent } from './page/home/home.component';

import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';

import { LivebodyCorpComponent } from './page/livebody-corp/livebody-corp.component';



import { CarouselModule } from 'ngx-owl-carousel-o';
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { FormsModule ,ReactiveFormsModule} from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';



@NgModule({
  declarations: [
    AppComponent,
   // HomeComponent,
    HeaderComponent,
    FooterComponent,

    LivebodyCorpComponent,
   
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CarouselModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LazyLoadImageModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
