import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//import { HomeComponent } from './page/livebody-corp/livebody-corp.component';
//import { HomeComponent } from './page/home/home.component';
import { LivebodyCorpComponent } from './page/livebody-corp/livebody-corp.component';

const routes: Routes = [
  { path: '', component: LivebodyCorpComponent },
 // { path: '', component: HomeComponent },
  { path:'**', redirectTo:'' , pathMatch:'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
