import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-livebody-corp',
  templateUrl: './livebody-corp.component.html',
  styleUrls: ['./livebody-corp.component.scss']
})
export class LivebodyCorpComponent implements OnInit {
  user = {
    name:'',
    phone:'',
    email:'',
    text:'  '
  }
  message:string = "";

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  register(fRegister: NgForm){
    if (this.user.name != "" && this.user.email != "" ) {
      if(this.user.phone == ""){
        this.user.phone = "  ";
      }
      this.http.post<any>('assets/email/emailContact.php', this.user).subscribe(data => {
        if(data == 'Exito'){
          this.message = "El correo fue enviado correctamente.";
          this.user.name = "";
          this.user.text = "";
          this.user.email = "";
          this.user.phone = "";
          setTimeout(()=>{  
            this.message = ""; 
          },3000);
        }
      },
      (error) =>{
        console.log("JS Call error: ", error);
        }); 
    } else {
      this.message = "Llenar los campos requeridos";
      setTimeout(()=>{  
        this.message = ""; 
      },3000); 
    }
  }


}
